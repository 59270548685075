import React from 'react'
import { Link } from "react-router-dom";
import {
  homeClick,
  underlineMenuAbout,
  underlineMenuContact,
  underlineMenuPortfolio,
} from "../../script/checkUnderline";
import '../../../src/PreviewPage.scss'; // Import your SCSS file for styling
import { useEffect } from 'react';
import Footer from '../../components/Footer';



export function useTitle(title) {
  useEffect(() => {
    const prevTitle = window.document.title
    window.document.title = title
    return () => {
      window.document.title = prevTitle
      console.log(`window title: ${window.document.title}`)
    }
  })
}

const TermsOfUse = () => {
  useTitle("Terms Of Use - RISE")

  return (
    <div className="creative-portfolio" style={{ minHeight: '100vh', background: '#ffffff', overflowX: 'hidden' }}>
      <div className="flex-box-header header" id="detail">
        <div className="headerText">
          <a href="/">
            <h2 className='headerTextCompany' >THE RISE COLLECTION</h2>
          </a>
        </div>
        <div className="menuItems">
          <Link to="/" onClick={homeClick}>
            <p id="homeMenuText">HOME</p>
          </Link>
          <Link to="/#about-us" onClick={underlineMenuAbout}>
            <p id="aboutUsMenuText">ABOUT US</p>
          </Link>
          <Link to="/#portfolio" onClick={underlineMenuPortfolio}>
            <p style={{ borderBottom: 'solid 2px', borderColor: 'red' }} id="gamesMenuText">PORTFOLIO</p>
          </Link>
          <Link to="/#contact-us" onClick={underlineMenuContact}>
            <p id="mediaMenuText">CONTACT US</p>
          </Link>
        </div>
      </div>
      <div className="app-container" >
        <div style={{ textAlign: 'center' }}>
          <h2 style={{
            fontSize: "30px", fontFamily: "Pixelify Sans, sans-serif",

          }}>TERMS OF USE</h2>
          <p style={{ fontSize: '20px' }}></p>



        </div>
        <p style={{ fontSize: '20px' }}>Welcome to THE RISE COLLECTION (“we,” “us,” or “our”). These Terms of Use (“Terms”) govern your access to and use of our website, software, services, and any related products, including but not limited to brands, software applications, digital assets, and other content (collectively, the “Services”) provided by THE RISE COLLECTION. By accessing or using our Services, you agree to be bound by these Terms. If you do not agree with these Terms, please do not use our Services.</p>
        <p style={{ fontSize: '20px' }}>Last Updated: 03/07/25</p>
        <ol>

          <li>
            <p style={{ fontSize: '20px', color: "black", fontWeight: 'bold', fontFamily: "Pixelify Sans, sans-serif" }}>ACCEPTANCE OF TERMS</p>
            <p style={{ fontSize: '20px' }}>By using our Services, you confirm that you are at least 18 years of age and have the legal capacity to enter into this agreement. If you are using the Services on behalf of a company, organization, or other entity, you represent that you have the authority to bind that entity to these Terms.</p>


          </li>

          <li>
            <p style={{ fontSize: '20px', color: "black", fontWeight: 'bold', fontFamily: "Pixelify Sans, sans-serif"  }}>DESCRIPTION OF SERVICES</p>
            <p style={{ fontSize: '20px' }}>THE RISE COLLECTION provides a range of services, including but not limited to: </p>
            <ul style={{ fontSize: '20px' }}>
              <li>Creation and management of brands;
              </li>
              <li>
                	Development and licensing of software applications;

              </li>
              <li>
                	Design and distribution of digital and physical assets.
              </li>
            </ul>
            <p style={{ fontSize: '20px' }}>We reserve the right to modify, suspend, or discontinue any part of the Services at any time, with or without notice.</p>
          </li>

<li>
  <p style={{ fontSize: '20px', color: "black", fontWeight: 'bold', fontFamily: "Pixelify Sans, sans-serif"  }}>USER ACCOUNTS</p>
  <p style={{ fontSize: '20px',  }}>To access certain features of our Services, you may need to create an account. You agree to:</p>
  <ul style={{ fontSize: '20px' }}>
    <li>
    Provide accurate, current, and complete information during registration;
    </li>
    <li>
    Maintain the security of your account credentials;
    </li>
    <li>
    Notify us immediately of any unauthorized use of your account.
    </li>
  </ul>
  <p style={{ fontSize: '20px' }}>You are solely responsible for all activities that occur under your account.</p>
</li>

<li>
  <p style={{fontSize: '20px' , color: "black", fontWeight: 'bold', fontFamily: "Pixelify Sans, sans-serif"}}>LICENSE TO USE OUR SERVICES</p>
  <p style={{fontSize: '20px'}}>Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-transferable, revocable license to access and use the Services for personal or business purposes, as applicable. This license does not permit you to:</p>
<ul style={{fontSize: '20px'}}>
  <li>Modify, reverse-engineer, decompile, or disassemble any part of the Services;</li>
  <li>Use the Services to create a competing product or service;</li>
  <li>Transfer or sublicense the Services to a third party without our prior written consent.</li>
</ul>
</li>
<li>
  <p style={{ fontSize: '20px', color: "black", fontWeight: 'bold', fontFamily: "Pixelify Sans, sans-serif" }}>INTELLECTUAL PROPERTY</p>
  <p style={{ fontSize: '20px' }}>All content, software, brands, designs, and assets provided through the Services (collectively, “Content”) are owned by THE RISE COLLECTION or our licensors and are protected by copyright, trademark, and other intellectual property laws. You may not reproduce, distribute, or create derivative works from the Content without our express written permission.</p>
  <p style={{ fontSize: '20px' }}>If you purchase or license specific assets or software from us, your rights to use such items will be governed by the applicable purchase or licensing agreement.</p>
</li>

<li>
  <p style={{ fontSize: '20px' , color: "black", fontWeight: 'bold', fontFamily: "Pixelify Sans, sans-serif" }}>USER CONTENT</p>
  <p style={{ fontSize: '20px' }}>You may have the opportunity to submit content, such as feedback, designs, or other materials (“User Content”), through the Services. By submitting User Content, you grant us a worldwide, royalty-free, perpetual, irrevocable license to use, modify, reproduce, and distribute that content in connection with our Services. You represent that you own or have the necessary rights to submit such User Content.</p>
</li>

<li>
<p style={{ fontSize: '20px', color: "black", fontWeight: 'bold', fontFamily: "Pixelify Sans, sans-serif" }}>PROHIBITED CONDUCT</p>
<p style={{ fontSize: '20px' }}>You agree not to:</p>
<ul style={{ fontSize: '20px' }}>
  <li>Use the Services for any illegal or unauthorized purpose;</li>
  <li>Interfere with or disrupt the operation of the Services, including by introducing viruses or other harmful code;</li>
  <li>Attempt to gain unauthorized access to our systems or networks;</li>
  <li>Harass, abuse, or harm other users of the Services.</li>
</ul>
</li>

<li>
  <p style={{ fontSize: '20px' , color: "black", fontWeight: 'bold', fontFamily: "Pixelify Sans, sans-serif" }}>FEES AND PAYMENT</p>
  <p style={{ fontSize: '20px' }}>Some of our Services may require payment. All fees will be disclosed prior to purchase. You agree to pay all applicable fees in accordance with the payment terms provided at the time of purchase. All sales are final unless otherwise stated in a separate agreement.</p>
</li>

<li>
  <p style={{ fontSize: '20px', color: "black", fontWeight: 'bold', fontFamily: "Pixelify Sans, sans-serif" }}>TERMINATION</p>
  <p style={{ fontSize: '20px' }}>We may suspend or terminate your access to the Services at our sole discretion, with or without notice, if you violate these Terms or for any other reason. Upon termination, your license to use the Services will cease, and you must discontinue all use of the Services.</p>
</li>

<li>
  <p style={{ fontSize: '20px', color: "black", fontWeight: 'bold', fontFamily: "Pixelify Sans, sans-serif" }}>DISCLAIMER OF WARRANTIES</p>
  <p style={{ fontSize: '20px' }}>The Services are provided “as is” and “as available” without warranties of any kind, whether express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, or non-infringement. We do not guarantee that the Services will be uninterrupted, error-free, or secure.</p>
</li>

<li>
  <p style={{ fontSize: '20px',  color: "black", fontWeight: 'bold', fontFamily: "Pixelify Sans, sans-serif" }}>LIMITATION OF LIABILITY</p>
  <p style={{ fontSize: '20px' }}>To the fullest extent permitted by law, THE RISE COLLECTION, its affiliates, and its employees, agents, or licensors will not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the Services, even if we have been advised of the possibility of such damages. Our total liability to you will not exceed the amount you paid us for the Services in the preceding 12 months.</p>

</li>

<li>
  <p style={{ fontSize: '20px',  color: "black", fontWeight: 'bold', fontFamily: "Pixelify Sans, sans-serif" }}>INDEMNIFICATION</p>
  <p style={{ fontSize: '20px' }}>You agree to indemnify and hold harmless THE RISE COLLECTION and its affiliates, officers, directors, employees, and agents from any claims, damages, losses, or expenses (including legal fees) arising from your use of the Services, your violation of these Terms, or your infringement of any third-party rights.</p>
</li>

<li>
  <p style={{ fontSize: '20px', color: "black", fontWeight: 'bold', fontFamily: "Pixelify Sans, sans-serif" }}>CHANGES TO THESE TERMS</p>
  <p style={{ fontSize: '20px' }}>We may update these Terms from time to time. If we make material changes, we will notify you by posting the updated Terms on our website or through other reasonable means. Your continued use of the Services after such changes constitutes your acceptance of the revised Terms.</p>
</li>

<li>
  <p style={{ fontSize: '20px', color: "black", fontWeight: 'bold', fontFamily: "Pixelify Sans, sans-serif" }}>GOVERNING LAWS</p>
  <p style={{ fontSize: '20px' }}>These Terms are governed by the laws of the State of South Carolina, United States, without regard to its conflict of law principles. Any disputes arising under these Terms will be resolved in the courts of Charleston, South Carolina.</p>
</li>

<li>
  <p style={{ fontSize: '20px', color: "black", fontWeight: 'bold', fontFamily: "Pixelify Sans, sans-serif" }}>CONTACT US</p>
  <p style={{ fontSize: '20px' }}>If you have any questions about these Terms or our Services, please contact us at: THE RISE COLLECTION, <a style={{ color: 'red', fontWeight: 'bold' }} href='mailto:contact@therisecollection.co'>contact@therisecollection.co </a> 

</p>
<p style={{ fontSize: '20px' }}>
</p>
</li>

        </ol>
      </div>

      <div style={{ backgroundColor: 'black', }}>
        <div className="footer-content" style={{ backgroundColor: 'black' }}>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default TermsOfUse
