import React from 'react'
import '../../../src/PreviewPage.scss'; // Import your SCSS file for styling
import { useEffect } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';



export function useTitle(title) {
  useEffect(() => {
    const prevTitle = window.document.title
    window.document.title = title
    return () => {
      window.document.title = prevTitle
      console.log(`window title: ${window.document.title}`)
    }
  })
}

const PrivacyPolicy = () => {
  useTitle("Privacy Policy - RISE")

  return (
    <div className="creative-portfolio" style={{ minHeight: '100vh', background: '#ffffff', overflowX: 'hidden' }}>
    <Header/>
      <div className="app-container" >
        <div style={{ textAlign: 'center' }}>
          <h2 style={{
            fontSize: "30px", fontFamily: "Pixelify Sans, sans-serif",

          }}>PRIVACY POLICY</h2>




        </div>
        <p style={{ fontSize: '20px' }}>Thank you for choosing THE RISE COLLECTION. We prioritize the privacy of your data and are committed to ensuring the security of your personal information. This privacy policy outlines how we collect, use, and safeguard data across all our software offerings.

          <br></br>
          <p style={{ fontSize: '25px', color: "black", fontWeight: 'bold',            fontFamily: "Pixelify Sans, sans-serif",
 }}>TABLE OF CONTENTS</p>
          <ol style={{ fontSize: '20px' }}>
            <li ><a href="#information-we-collect" style={{ color: 'red', fontWeight: 'bold' }}>Information We Collect</a></li>
            <li><a href="#how-we-use-your-information" style={{ color: 'red', fontWeight: 'bold' }}>How We Use Your Information</a></li>
            <li><a href="#data-security" style={{ color: 'red', fontWeight: 'bold' }}>Data Security</a></li>
            <li><a href="#data-retention" style={{ color: 'red', fontWeight: 'bold' }}>Data Retention</a></li>
            <li><a href="#your-choices" style={{ color: 'red', fontWeight: 'bold' }}>Your Choices</a></li>
            <li><a href="#updates-to-privacy-policy" style={{ color: 'red', fontWeight: 'bold' }}>Updates to Privacy Policy</a></li>
            <li id='information-we-collect'><a href="#contact-information" style={{ color: 'red', fontWeight: 'bold' }}>Contact Information</a></li>

          </ol>
          <br ></br> Last updated: 3/16/25</p>

        <br></br>

        <div style={{ marginBottom: '20px' }}>
          <h2 style={{ fontSize: '25px', color: "black", fontWeight: 'bold',             fontFamily: "Pixelify Sans, sans-serif",
 }}>INFORMATION WE COLLECT</h2>
          <p style={{ fontSize: '20px' }}>
            In our software, we only collect essential information necessary for optimal functionality, including: <br></br><br></br>

            - Usernames and email addresses for account creation. <br id='how-we-use-your-information'></br><br></br>

            - Usage data to enhance the performance of our software.
          </p>
          <br></br>

        </div>
        <h2 id='data-security' style={{ fontSize: '25px', color: "black", fontWeight: 'bold',             fontFamily: "Pixelify Sans, sans-serif",
 }}>HOW WE USE YOUR INFORMATION
        </h2 >

        <p style={{ fontSize: '20px' }}>
          We never share your information with third parties for marketing or other purposes.
        </p>
        <br></br>

        <h2 style={{ fontSize: '25px', color: "black", fontWeight: 'bold',             fontFamily: "Pixelify Sans, sans-serif",
 }}>DATA SECURITY</h2>
        <p style={{ fontSize: '20px' }}>
          We employ industry-standard security measures to protect your data from unauthorized access, disclosure, alteration, and destruction, ensuring a secure software experience.
        </p>
        <hr id='data-retention' />
        <h2 style={{ fontSize: '25px', color: "black", fontWeight: 'bold',             fontFamily: "Pixelify Sans, sans-serif",
 }}>DATA RETENTION
        </h2>
        <p style={{ fontSize: '20px' }}>
          We retain your data only for the duration necessary to fulfill the purposes outlined in this privacy policy, unless a longer retention period is required or permitted by law.
        </p>
        <hr id='your-choices' />
        <h2 style={{ fontSize: '25px', color: "black", fontWeight: 'bold',             fontFamily: "Pixelify Sans, sans-serif",
 }}>YOUR CHOICES</h2>
        <p style={{ fontSize: '20px' }}>
          You have the right to review, update, or delete your personal information. Please contact us at <a style={{ color: 'red', fontWeight: 'bold' }} href='mailto:contact@therisecollection.co'>contact@therisecollection.co </a>       </p>
        <hr id='updates-to-privacy-policy' />
        <h2 style={{ fontSize: '25px', color: "black", fontWeight: 'bold',             fontFamily: "Pixelify Sans, sans-serif",
 }}>UPDATES TO PRIVACY POLICY</h2>
        <p style={{ fontSize: '20px' }}>
          This privacy policy may be updated to reflect changes in our software and practices. We encourage you to regularly review this page for any updates.</p>
        <hr id='contact-information' />
        <h2 style={{ fontSize: '25px', color: "black", fontWeight: 'bold',             fontFamily: "Pixelify Sans, sans-serif",
 }}>CONTACT INFORMATION</h2>
        <p style={{ fontSize: '20px', paddingBottom: '25px' }}>
          For any questions or concerns regarding this privacy policy, please contact us at <a style={{ color: 'red', fontWeight: 'bold' }} href='mailto:contact@therisecollection.co'>contact@therisecollection.co </a>       </p>

      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <div style={{backgroundColor: 'black', }}>
        <div className="footer-content" style={{backgroundColor: 'black'}}>
        <Footer/>
        </div>
</div>
    </div>
  )
}

export default PrivacyPolicy
